import axios from 'axios';
import { TOKEN, settings } from 'utils';

export const getPublicToken = async () => {
  return await axios({
    url: `${process.env.REACT_APP_REST_API_URL}/v1/access/token`,
    method: 'GET',
  })
    .then((response) => {
      return 'Bearer ' + response.data.token;
    })
    .catch(() => {
      return '';
    });
};

export const updatePlaidFail = async (uid: string, flag: boolean) => {
  return await axios({
    url: `${process.env.REACT_APP_REST_API_URL}/v1/business/applications/call/plaid_fail`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: await getPublicToken(),
    },
    data: JSON.stringify({
      vars: {
        uid: uid,
        plaid_failed: flag,
      },
    }),
  });
};

export const sendLeadNotification = async (id: string) => {
  return await axios({
    url: `${process.env.REACT_APP_REST_API_URL}/v1/onboarding/scf/importers/notify_customer?id=${id}`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${settings.getCookie(TOKEN)}`,
    },
  });
};

export const sendConsumerCreditConsent = async () => {
  return await axios({
    url: `${process.env.REACT_APP_REST_API_URL}/v2/business/consents`,
    method: 'POST',
    data: JSON.stringify({
      consent_name: 'consumer_credit_report',
      decision: 'yes',
    }),
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${settings.getCookie(TOKEN)}`,
    },
  });
};

export const fetchPlaidItems = async (businessId: string) => {
  return await axios({
    url: `${process.env.REACT_APP_REST_API_URL}/v1/plaid/items`,
    params: {
      business_id: businessId,
    },
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${settings.getCookie(TOKEN)}`,
    },
  });
};
