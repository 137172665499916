import React from 'react';
import {
  CssOverrideType,
  Snackbar as SnackBarComponent,
} from '@dripcapital/dripui';
import { SnackbarVariantEnum } from 'enums';

type Props = CssOverrideType & {
  open: boolean;
  title: string;
  variant: SnackbarVariantEnum;
  autoHideDuration?: number;
  onClose: () => void;
};

const Snackbar: React.FC<Props> = ({
  open,
  title,
  variant,
  onClose,
  autoHideDuration = 3000,
  className = '',
  _sx = {},
}) => {
  return (
    <SnackBarComponent
      dataTestId="snackbar-element"
      open={open}
      title={title}
      variant={variant}
      autoHideDuration={autoHideDuration}
      anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
      _sx={_sx}
      className={className}
      onClose={(event: any, reason?: string) => {
        if (!['escapeKeyDown', 'clickaway'].includes(reason || '')) {
          onClose();
        }
      }}
    />
  );
};

export default Snackbar;
